.project-card-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 60px);
  width: 100%;
  padding: 10vh 10vw;
}

.project-card-main-container * {
  color: #dddddd;
  text-decoration-color: #dddddd;
}

.project-card-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 1024px;
  max-height: 512px;
  border-radius: 24px;
  cursor: pointer;
}

.project-card-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 100%;
  padding: 24px;
  padding-right: 12px;
}

.project-card-image-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.project-card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  height: 100%;
  padding: 48px;
  padding-left: 12px;
}

.project-card-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.project-card-content h1 {
  margin-bottom: 24px;
}

.project-card-description {
  margin-bottom: 24px;
}

.project-card-description a {
  display: inline-block;
}

@media (max-width: 780px) {
  .project-card-main-container {
    height: unset;
    padding: 5vh 10vw;
  }
  .project-card-container {
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-height: none;
  }
  .project-card-image-container {
    width: 100%;
    height: unset;
    padding-right: 24px;
  }
  .project-card-content {
    width: 100%;
    height: unset;
    padding: 24px;
    padding-left: 24px;
  }
}
