* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.main-container {
  position: relative;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(#333333, #000000);
  overflow: hidden;
}
